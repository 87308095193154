<template>
  <div class="popularize">
    <van-nav-bar title="我的推广" left-arrow @click-left="onClickLeft" />
    <main>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多"
        @load="onLoad"
      >
        <div 
          class="box fl-between" 
          v-for="(item, index) in boxList" 
          :key="index"
          :style="boxList.length === index + 1 ? 'border: none' : ''">
          <div class="boxcontent fl-between">
            <div class="left">
              <img class="img" :src="item.head" alt="">
              <div class="txtAll">
                <div class="top">
                  <p class="id">ID：{{item.id}}</p>
                  <div class="shu"></div>         
                  <p class="name">{{item.nickname}}</p>
                </div>
                <div class="bom">{{item.name ? item.name : '无'}} / {{item.phone ? item.phone : '无'}}</div>
              </div>
            </div>
            <div class="right" :style="item.sigin ? 'color: #FA6400' : 'color: #6E7278' ">{{item.sigin ? '已报名' : '未报名'}}</div>
          </div>
        </div>
      </van-list>
    </main>
  </div>
</template>

<script>
import { myPromotion } from '@/api'
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      boxList: [],
      loading: false,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      total: 0
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onClickLeft() {
      this.$router.back(-1)
    },
    getList() {
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      myPromotion(data).then(res => {
        this.total = res.data.total;
        if(this.total <= this.pageSize){
          this.boxList = res.data.list;
        }else{
          this.pageNum++;
          let arr = res.data.list;
          this.boxList = this.boxList.concat(arr);
        }
        this.loading = false;
        // 数据全部加载完成
        if (this.boxList.length >= this.total) {
          this.finished = true;//结束，显示我也是有底线的
        }
      })
    },
    onLoad() {
      this.getList()
    }
  },
};
</script>

<style scoped lang="scss">
.popularize{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  main{
    flex: 1;
    margin-top: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    .box{
      width: 340px;
      height: 60px;
      border-bottom: 1px solid rgba($color: #000, $alpha: .2);
      .boxcontent{
        width: 315px;
        height: 41px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .left{
          height: 41px;
          display: flex;
          align-items: center;
          .img{
            width: 36px;
            height: 36px;
            border-radius: 10px;
          }
          .txtAll{
            padding-left: 15px;
            .top{
              display: flex;
              align-items: center;
              padding-top: 11px;
              .id{
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 20px;
              }
              .shu{
                width: 1px;
                height: 12px;
                background: #D8D8D8;
                border-right: 1px solid #979797;
                margin-left: 8px;
              }
              .name{
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #A0A0A0;
                line-height: 17px;
                padding: 10px;
              }
            }
            .bom{
              padding-top: 4;
              font-size: 12px;
              font-family: SourceHanSansSC-Normal, SourceHanSansSC;
              font-weight: 400;
              color: #6E7278;
              line-height: 15px;
            }
          }
        }
        .right{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6E7278;
          line-height: 14px;
        }
      }
    }
  }
}
</style>
